import useAPI, { ky } from "./";

export async function login(email, password) {
  return await ky.post("auth/signin", { json: { email, password } }).json();
}

export function useUser() {
  const { data: user = {}, mutate, ...rest } = useAPI("auth/self", true);

  function logout() {
    return ky.get("auth/signout").then(() => mutate(null));
  }

  return { user, logout, ...rest };
}
