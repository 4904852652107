import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { lazy } from "react";

import Header from "./stories/TopNav";
import { useUser } from "./api/auth";
import { useTracking } from "./util/useTracking";

const Error404 = lazy(() => import("./routes/Errors/404"));
const Maintenance = lazy(() => import("./routes/Errors/Maintenance"));
const ErrorUnplanned = lazy(() => import("./routes/Errors/unplanned"));

const LoginPage = lazy(() => import("./routes/Auth/Login"));
const ResetPage = lazy(() => import("./routes/Auth/Reset"));
const ForgotPage = lazy(() => import("./routes/Auth/Forgot"));

const Reports = lazy(() => import("./routes/Reports/index.js"));
const Coordinator = lazy(() => import("./routes/Coordinator/index.js"));
const Audit = lazy(() => import("./routes/Audits/index.js"));
const Feedback = lazy(() => import("./routes/Feedback/index.js"));

/**
 * Adds the header component to routes.
 */
const WithHeader = ({ children }) => {
  return (
    <>
      <Header />
      {children}
    </>
  );
};

/**
 * Protects routes that require authentication.
 */
const ProtectedRoute = ({ children }) => {
  const { user, error, loading } = useUser();
  const location = useLocation();

  if (!user && !(!error && loading))
    return (
      <Navigate
        replace
        to={{
          pathname: "/login",
          state: { from: location },
        }}
      />
    );

  if (user && user.role) {
    const roleMap = {
      audit: ["/audits"],
      report: ["/reports"],
      manage: ["/audits", "/reports"],
      admin: ["/audits", "/reports", "/coordinator"],
      super: ["/audits", "/reports", "/coordinator"],
    };
    if (!roleMap[user.role].some((root) => location.pathname.startsWith(root)))
      return (
        <Navigate
          replace
          to={{
            pathname: roleMap[user.role][0],
          }}
        />
      );
  }

  return children;
};

export default function Router(props) {
  // GA tracking.
  useTracking();

  return (
    <>
      <Routes>
        { process.env.REACT_APP_MAINTENANCE==='true' ?
          <>
            <Route path="maintenance" element={<Maintenance />} />
            <Route path="/*" element={<Navigate to="/maintenance" />} />
          </>
        :<>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="forgot" element={<ForgotPage />} />
          <Route path="reset/:token" element={<ResetPage />} />
          <Route path="set/:token" element={<ResetPage setting={true} />} />
          <Route path="/opinion/*" element={<Feedback />} />
          <Route path="/error/down" element={<ErrorUnplanned />} />

          <Route
            path="/reports/*"
            element={
              <ProtectedRoute>
                <WithHeader>
                  <Reports />
                </WithHeader>
              </ProtectedRoute>
            }
          />
          <Route
            path="/coordinator/*"
            element={
              <ProtectedRoute>
                <WithHeader>
                  <Coordinator />
                </WithHeader>
              </ProtectedRoute>
            }
          />
          <Route
            path="/audits/*"
            element={
              <ProtectedRoute>
                <WithHeader>
                  <Audit />
                </WithHeader>
              </ProtectedRoute>
            }
          />

          <Route path="*" element={<Error404 />} />
        </>}
      </Routes>
    </>
  );
}
