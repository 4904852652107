import React from "react";
import BlockUi from "react-block-ui";
import { Rings } from "react-loader-spinner";
import "react-block-ui/style.css";

export default function Loader({ loading, children }) {
  return (
    <BlockUi
      blocking={loading}
      loader={<Rings color="#00BFFF" height={80} width={80} />}
      renderChildren={false}
      children={children}
      keepInView
    />
  );
}
