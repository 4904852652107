import React from "react";
import "./TopNav.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "./assets/logo.svg";
import { useUser } from "../api/auth";
import Loader from "./Loader";

const menuItems = [
  { text: "Audit on PEP", path: "/audits/" },
  { text: "Reports", path: "/reports/" },
  { text: "Coordinator", path: "/coordinator/" },
];

export function TopNav({ path }) {
  const navigate = useNavigate();
  const { user, logout } = useUser();
  let role = "audit";
  if (!user) return <Loader />;
  if (user.role) role = user.role;

  const roleMap = {
    audit: [menuItems[0]],
    report: [menuItems[1]],
    manage: [menuItems[0], menuItems[1]],
    admin: [menuItems[0], menuItems[1], menuItems[2]],
    super: [menuItems[0], menuItems[1], menuItems[2]],
  };

  return (
    <header>
      <span className="logo">
        <Logo />
      </span>
      {(roleMap[role] || []).map((item) => (
        <Link
          key={item.path}
          to={item.path}
          className={"route" + (path.includes(item.path) ? " active" : "")}
        >
          {item.text}
        </Link>
      ))}
      <span
        onClick={() => {
          logout();
          navigate("/login");
        }}
      >
        Logout
      </span>
    </header>
  );
}

export function FeedbackHeader({ title }) {
  return (
    <header>
      <span className="logo">
        <Logo />
      </span>
      <h1>PEP Opinion - {title}</h1>
    </header>
  );
}

export default function WrappedTopNav() {
  const { pathname } = useLocation();
  return <TopNav path={pathname} />;
}
