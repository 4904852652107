import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * Binds to the global GA script and reports any SPA page changes.
 */
export const useTracking = () => {
  // Location hook.
  const location = useLocation();

  // GA binding method.
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }

  // Watch for changes and report them.
  useEffect(() => {
    gtag("set", "page_path", location?.pathname + location.search);
    gtag("event", "page_view");
  }, [location]);
};
