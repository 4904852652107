import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router";
import Helmet from "react-helmet";
import Suspense from "./Suspense";

/* Toast library. */
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import React from "react";

function App() {
  if (process.env.NODE_ENV !== "production") {
    const { whyDidYouUpdate } = require("why-did-you-update");
    // whyDidYouUpdate(React);
  }

  return (
    <>
      <Helmet titleTemplate="%s | Invision360" defer={false} />
      <BrowserRouter>
        <Suspense>
          <Router />
        </Suspense>
        <ToastContainer autoClose={3000} />
      </BrowserRouter>
    </>
  );
}

export default App;
